/* global Greenhouse */

import Rollbar from 'rollbar/dist/rollbar.umd.min';
import _ from 'underscore';

const isWebpackStacktrace = function (stack) {
  const lines = stack.split('\n');
  const firstFewLines = lines.slice(0, 4);
  return _.find(firstFewLines, (line) => {
    return line.match(/assets\/webpack/);
  });
};

export const checkIgnore = (uncaught, rollbarReport) => {
  try {
    if (!uncaught) {
      return false;
    }
    const stack = rollbarReport[1].stack;
    return !isWebpackStacktrace(stack);
  } catch (e) {
    return true;
  }
};

const ROLLBAR_CONFIG = {
  captureUncaught: true,
  captureUnhandledRejections: false,
  checkIgnore: checkIgnore,
  ignoredMessages: [
    /Cannot read properties of undefined \(reading 'dragging'\)/i,
    /Cannot set properties of undefined \(setting 'dragging'\)/i,
  ],
  accessToken: '9b4b28791d73400ca543c04cb715b607',
  payload: {
    client: {
      javascript: {
        code_version: window.__WEBPACK_SETUP_DATA__.gitSha,
        source_map_enabled: true,
      },
    },
    environment: !!__PROD__ ? 'production' : 'development',
    person: {
      id: Greenhouse?.userId,
    },
  },
};

const rollbar = new Rollbar(ROLLBAR_CONFIG);
export default rollbar;
