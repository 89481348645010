import moment from 'moment';
import { locale, phantomLocale } from './translation';

// NOTE, this will change the global moment
// Our locales don't necessarily map 1:1 with moment. While they do for now, it's better to be explicit.
export const addMomentLocale = () => {
  if (phantomLocale() === 'spooky') {
    require('moment/locale/x-pseudo');
    moment.locale('x-pseudo');
    return;
  }

  switch (locale()) {
    case 'en': {
      moment.locale('en');
      break;
    }
    case 'it': {
      require('moment/locale/it');
      moment.locale('it');
      break;
    }
    case 'es': {
      require('moment/locale/es');
      moment.locale('es');
      break;
    }
    case 'de': {
      require('moment/locale/de');
      moment.locale('de');
      break;
    }
    case 'pt': {
      require('moment/locale/pt');
      moment.locale('pt');
      break;
    }
    case 'fr': {
      require('moment/locale/fr');
      moment.locale('fr');
      break;
    }
    default: {
      moment.locale('en');
      break;
    }
  }
};
