class IllegalHTMLFormatError extends Error {
  constructor(message) {
    super(message);
    this.name = 'IllegalHTMLFormatError';
  }
}

class IllegalStateError extends Error {
  constructor(message) {
    super(message);
    this.name = 'IllegalStateError';
  }
}

export { IllegalHTMLFormatError, IllegalStateError };
