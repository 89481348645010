import documentReady from 'document-ready';
import configureAnnouncementPartial from 'legacy/announcements';
import Flash from 'legacy/flash';
import $ from 'legacy/jquery';
import 'legacy/location_control';
import { prettyPrint } from 'legacy/prettify';
import TimeZones from 'legacy/time_zones';
import Tooltip from 'legacy/tooltip';
import { preventDefaultPlaceholderAnchorTags } from 'legacy/util/prevent_default_placeholder_anchor_tags';
import { addMomentLocale } from 'shared/utils/moment_locale_helper';
import t, { tList } from 'shared/utils/translation';

preventDefaultPlaceholderAnchorTags();
configureAnnouncementPartial();
addMomentLocale();

documentReady(function () {
  document.cookie = 'gh_tz=' + TimeZones.detect() + ';path=/';

  Tooltip.init();
  Flash.fadeOutAfterDelay();

  prettyPrint();

  // For all cancel events, we want to kick the user back to the previous page
  $('#cancel').click(function () {
    history.back();
    return false;
  });

  $(window).resize(function () {
    $('.modal.ui-dialog-content').dialog('option', 'position', {
      my: 'center',
      at: 'center',
      of: window,
    });
  });

  // translate datepicker strings
  $.datepicker.setDefaults({
    closeText: t('datepicker.closeText'),
    prevText: t('datepicker.prevText'),
    nextText: t('datepicker.nextText'),
    currentText: t('datepicker.currentText'),
    weekHeader: t('datepicker.weekHeader'),
    monthNames: tList('date.month_names', null, true), // set month names
    monthNamesShort: tList('date.abbr_month_names', null, true), // set short month names
    dayNames: tList('date.day_names'), // set days names
    dayNamesShort: tList('date.abbr_day_names'), // set short day names
    dayNamesMin: tList('date.abbr_day_names_short'), // set more short days names
  });
});

/**
 * Fix issues where jQuery UI and TinyMCE/Select2 don't play nice with each other.
 * - Trying to insert a hyperlink while a modal was open would prevent the hyperlink modal's
 *   fields from being selectable.
 * - A select2 search box will not grab focus consistently.
 *
 * Sources:
 * - http://www.tinymce.com/develop/bugtracker_view.php?id=5917
 * - http://stackoverflow.com/a/18386569
 *
 * Updated for TinyMCE v5: https://www.tiny.cloud/docs/integrations/bootstrap/#usingtinymceinabootstrapdialog
 */
$.widget('ui.dialog', $.ui.dialog, {
  _allowInteraction: function (event) {
    return (
      !!$(event.target).closest(
        '.tox-tinymce, .tox-tinymce-aux, .moxman-container, .select2-drop'
      ).length || this._super(event)
    );
  },
});

/**
 * Patch $._evalUrl to always re-use the current page's nonce (but only for scripts on our CDN).
 *
 * Our legacy javascript has a pattern of using jQuery to fetch html from the server and add it to the page.
 * When that html includes script tags, jquery uses $._evalUrl to get the script contents via ajax and insert the
 * contents as an inline script tag (to ensure the scripts are evaluated synchronously).
 *
 * We want a strict CSP policy that does not allow inline scripts to run unless they have a nonce.
 *
 * $._evalUrl supports a nonce option to forward the nonce from the original node to the new inline script, but because
 * our html is fetched via xhr, the script tag from the ajax response has a different nonce than the original page's
 * policy.
 *
 * jQuery will stop transforming script tag with sources into inline scripts in the next major version (4.0) based on
 * this issue: https://github.com/jquery/jquery/issues/1895
 */
const oldEvalUrl = $._evalUrl;
$._evalUrl = function (url, options, doc) {
  const applicationEntrypoints = $('#application-entrypoint');
  if (applicationEntrypoints.length === 1) {
    const applicationEntrypoint = applicationEntrypoints[0];
    const isOurScript =
      new URL(applicationEntrypoint.src).hostname === new URL(url).hostname;
    if (isOurScript) {
      options.nonce = applicationEntrypoint.nonce;
    }
  }
  return oldEvalUrl(url, options, doc);
};
