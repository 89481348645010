/*
 * Assigns the location to the given URL.
 * This thin wrapper is needed to mock the location object in tests after JSDOM 22
 * https://github.com/jsdom/jsdom/issues/3492
 */
const assignLocation = (url: string | URL) => {
  return window.location.assign(url);
};

const replaceLocation = (url: string | URL) => {
  window.location.replace(url);
};

function navigateTo(newPage: string) {
  // @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'Location'.
  window.location = newPage;
}

function openAndSwitchTo(url: string | URL) {
  return window.open(url, '_blank')?.focus();
}

function queryString() {
  return window.location.search;
}

function reload() {
  window.location.reload();
}

function delayedReload(waitTime = 0) {
  setTimeout(() => window.location.reload(), waitTime);
}

export {
  assignLocation,
  replaceLocation,
  navigateTo,
  queryString,
  reload,
  delayedReload,
  openAndSwitchTo,
};
